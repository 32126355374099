import Colors from "../../../utils/Colors"

class CalendarDataMessage {
    constructor(id = -1, label = "") {
        this.id = id
        this.label = label
        this.tagColor = ""
        this.status = ""
        this.data = null
    }    
 }
 export default CalendarDataMessage