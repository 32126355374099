

class CalendarData {
    constructor(id = -1, label = "", date = 1) {
        this.id = id;
        this.label = label
        this.labelClass = ""
        this.date = date; //日期
        this.year = 1979;
        this.month = 0; //表示月份的整数值，从 0（1 月）到 11（12 月）
        this.className = "";
        this.cursor = "";
        this.messages = []
        this.status = "";
        this.color = ""
    }    
 }
 export default CalendarData